<form [formGroup]="form">
    <ng-container *ngIf="!!list">
      <table class="table table whitebox-inner" *ngIf="list?.weeks?.length > 0">
        <thead>
        <tr>
          <th [colSpan]="6 + fieldList.length"></th>
          <th class="align-right">
            <i class="fa fa-arrow-circle-left fa-2x ev-blue-color"
               ngbTooltip="Woche früher"
               (click)="weekScrollPage(-1)">
            </i>
          </th>
          <th colspan="2"
              [ngClass]="{'frame-week': (list.weeks[index].first_day_of_week|date:'dd.MM.yyyy') === (selectionData.week_start?.first_day_of_week|date:'dd.MM.yyyy')}"
              *ngFor="let index of weekPaginator.visibleWeekIndices">
            <div [ngbTooltip]="'Ab ' + (list.weeks[index].first_day_of_week|date:'dd.MM.yyyy') + (((list.weeks[index].first_day_of_week|date:'dd.MM.yyyy') === (selectionData.week_start?.first_day_of_week|date:'dd.MM.yyyy')) ? ' Material wird in dieser Woche benötigt' : '')">
              <strong>
                KW&nbsp;{{ list.weeks[index].week }}/{{ list.weeks[index].first_day_of_week|date:'yy' }}
              </strong>
              <div style="display:block; font-size:10px;">
                {{ list.weeks[index].first_day_of_week|date:'dd.MM.yyyy' }}
              </div>
            </div>
          </th>
          <th>
            <i class="fa fa-arrow-circle-right fa-2x ev-blue-color"
               ngbTooltip="Woche später"
               (click)="weekScrollPage(1)">
            </i>
          </th>
        </tr>
        <tr>
          <th>
            <a (click)="sort('supplier')">
              Lieferant
            </a>
          </th>
          <th>
            <a (click)="sort('article_name')">
              Bezeichnung
            </a>
          </th>
          <th *ngFor="let field of fieldList">{{field.name}}</th>
          <th class="align-right">Lagerbestand</th>
          <th class="align-right">Bestellt</th>
          <th class="align-right">Bestätigt</th>
          <th class="align-right"><span ngbTooltip="Überfällig">Überf.</span></th>
          <th class="align-right">Planung</th>
          <ng-container *ngFor="let index of weekPaginator.visibleWeekIndices">
            <th class="align-right">
              <i class="fa fa-truck"
                 [ngbTooltip]="'bestätigte Lieferungen in KW ' + list.weeks[index].week">
              </i>
            </th>
            <th class="align-right">
              <i class="fa fa-handshake-o"
                 [ngbTooltip]="'geplante Materialnutzung in KW ' + list.weeks[index].week">
              </i>
            </th>
          </ng-container>
          <th></th>
        </tr>
        <tr class="filterrow">
          <th>
            <material-supplier-select-search formControlName="supplier_id"
                                             [groupId]="currentGroupId"
                                             [ngClass]="{ 'red_alert': !!filterFormErrors.supplier_id }">
            </material-supplier-select-search>
          </th>

          <th>
            <ng-container *ngIf="articleNames.data$ | async as articles; else loadingOrError.template">
              <select class="form-control input-sm"
                      name="article_name"
                      formControlName="article_name"
                      [ngClass]="{ 'red_alert': !!filterFormErrors.article_name }">
                <option [ngValue]="null">-</option>
                <option [ngValue]="article" *ngFor="let article of articles">{{article}}</option>
              </select>
            </ng-container>
            <loading-or-error #loadingOrError
                              [loadingWrapper]="articleNames"
                              [errorMessage]="'Konnte Artikelnamen nicht laden'">
            </loading-or-error>
          </th>

          <th *ngFor="let field of fieldList">
            <ng-container [ngSwitch]="field.data_type">
              <ng-container *ngSwitchCase="'Auswahl'">
                <select class="form-control input-sm"
                        [name]="field.html_name"
                        [ngClass]="{ 'red_alert': !!filterFormErrors[field.html_name] }"
                        [formControlName]="field.html_name">
                  <option [ngValue]="null" selected>-</option>
                  <option [ngValue]="missingChoices[field.html_name]"
                          *ngIf="!!missingChoices[field.html_name]">
                    {{missingChoices[field.html_name]}}
                  </option>
                  <option [ngValue]="choice" *ngFor="let choice of field.choices">{{choice}}</option>
                </select>
              </ng-container>
              <ng-container [ngSwitch]="this.form[field.html_name]" *ngSwitchCase="'Option'">
                <i class="fa fa-check click" (click)="triStateChange(field)" *ngSwitchCase="true"></i>
                <i class="fa fa-check click" (click)="triStateChange(field)" *ngSwitchCase="'true'"></i>
                <i class="fa fa-times click" (click)="triStateChange(field)" *ngSwitchCase="false"></i>
                <i class="fa fa-times click" (click)="triStateChange(field)" *ngSwitchCase="'false'"></i>
                <i class="fa fa-check click gray_font" (click)="triStateChange(field)" *ngSwitchDefault></i>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input class="form-control input-sm"
                       [name]="field.html_name"
                       [ngClass]="{ 'red_alert': !!filterFormErrors[field.html_name] }"
                       [formControlName]="field.html_name">
              </ng-container>
            </ng-container>
          </th>

          <th [colSpan]="6 + weekPaginator.visibleWeekIndices.length * 2"></th>
        </tr>
        </thead>

        <tbody>

        <tr [business-order-material-unlimited-list-row]="material"
            [business-order-material-unlimited-list-row-selection-data]="selectionData"
            [business-order-material-unlimited-list-row-marked-week]="selectedWeek"
            [business-order-material-unlimited-list-row-marked-variation-id]="selectedVariationId"
            [business-order-material-unlimited-list-row-week-pages]="weekPaginator.visibleWeekIndices"
            [business-order-material-unlimited-list-row-weeks]="list.weeks"
            [business-order-material-unlimited-list-row-fields]="fieldList"
            [business-order-material-unlimited-list-row-lock-selection]="lockSelection"
            (business-order-material-unlimited-list-row-select)="emitSelect.emit($event)"
            (business-order-material-unlimited-list-row-mark-week)="setSelectedWeek($event, material.inventory.id)"
            *ngFor="let material of list.objects; let index = index">
        </tr>
        </tbody>
      </table>
      <div *ngIf="list.count === 0">
        Keine Material Artikel mit diesen Kriterien Gefunden
      </div>
      <ev-navigation [page]="list.page" [pages]="list.pages" [query]="form.value" (goto)="goto($event)"></ev-navigation>
    </ng-container>
    <ng-container *ngIf="!list">Liste wird geladen</ng-container>


</form>
