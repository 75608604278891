<div class="modal-header">
  <div class="modal-title">
    <h3>Betriebsauftrag {{ order.id }}-{{ order.index }} starten</h3>
  </div>
</div>

<div class="modal-body">
  <div class="red_alert" *ngIf="errors.length > 0">
    <div>
      <strong>Achtung: BA konnte nicht gestartet werden.</strong>
    </div>
    <ng-container *ngFor="let error of errors">
      <ng-container [ngSwitch]="error">
        <div *ngSwitchCase="'database.error'">
          Änderungen zum starten des BAs konnten nicht gespeichert werden. Bitte versuchen Sie es erneut.
        </div>
        <div *ngSwitchCase="'not.found.business.order'">
          BA konnte nicht gefunden werden. Möglicher weise wurde er storniert oder ist bereits gestartet.
        </div>
        <div *ngSwitchCase="'already.started.business.order'">
          BA konnte nicht gefunden werden. Möglicher weise wurde er storniert oder ist bereits gestartet.
        </div>
        <div *ngSwitchCase="'not.found.business.order.reproduction.reason'">
          BA konnte nicht gefunden werden. Möglicher weise wurde er storniert oder ist bereits gestartet.
        </div>
        <div *ngSwitchCase="'not.ready.business.order'">
          BA konnte nicht gefunden werden. Möglicher weise wurde er storniert oder ist bereits gestartet.
        </div>
        <div *ngSwitchCase="'deleted.business.order'">
          Der ausgewählte BA wurde storniert.
        </div>

        <div *ngSwitchCase="'not.found.article'">
          Artikel zum BA konnte nicht gefunden werden.
        </div>
        <div *ngSwitchCase="'not.found.article.change.detection'">
          Änderungen an der Fertigungsliste zum BA konnten nicht gefunden werden.
        </div>
        <div *ngSwitchCase="'deleted.article'">
          Artikel zum BA wurde gelöscht.
        </div>
        <div *ngSwitchCase="'locked.article'">
          Artikel zum BA wurde gesperrt.
        </div>
        <div *ngSwitchCase="'not.valid.article.change.detection'">
          Bitte überprüfen Sie die Artikeldaten und geben Sie die Fertigungsliste frei.
        </div>
        <div *ngSwitchCase="'not.found.article.history'">
          Bitte überprüfen Sie die Artikeldaten und geben Sie die Fertigungsliste frei.
        </div>
        <div *ngSwitchCase="'not.found.article.data.utilize.sum'">
          Bitte überprüfen Sie die das Format in den Artikeldaten.
        </div>
        <div *ngSwitchCase="'not.found.article.production.list'">
          Bitte überprüfen Sie die Fertigungsliste.
        </div>

        <div *ngSwitchCase="'incorrect.material'">
          Material Zuordnungsfehler. Bitte die Artikeldaten prüfen oder Material manuell auswählen.
        </div>
        <div *ngSwitchCase="'incorrect.material.base'">
          Konnte kein Basismaterial zuordnen.
        </div>
        <div *ngSwitchCase="'incorrect.material.cu-foil'">
          Konnte kein Cu-Folien Material zuordnen.
        </div>
        <div *ngSwitchCase="'incorrect.material.prepreg'">
          Konnte kein Prepreg Material zuordnen.
        </div>
        <div *ngSwitchCase="'material.not.sufficient'">
          Material Zuordnungsfehler. Bitte Materialverfügbarkeit der Cu-Folie prüfen.
        </div>
        <div *ngSwitchCase="'incorrect.target.material'">
          Material Zuordnungsfehler. Zielmaterial konnte nicht gesetzt werden.
        </div>

        <div *ngSwitchDefault>
          Unbekannter Fehler. Bitte Artikeldaten prüfen.
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="modal-footer">
  <loading-button [clazz]="'btn btn-success btn-sm pull-right'" (loadingSave)="submit($event)">
    Betriebsauftrag starten
  </loading-button>
</div>
