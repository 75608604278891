<div class="article-form-item" [formGroup]="form" *ngIf="view">
  <label [htmlFor]="'article-model-' + model" class="article-form-item-label" *ngIf="label">{{ label }}:</label>

  <div class="article-form-item-padding">
  </div>

  <div class="article-form-item-data">

    <div class="article-form-data-content" [ngClass]="cssClass">
      <input type="text"
             class="form-control"
             style="text-align: left"
             [id]="'article-model-' + model"
             [attr.disabled]="isDisabled"
             [formControlName]="model"
             (change)="change()">
    </div>
  </div>

  <div style="display: table-cell;">
    <article-changed-marker [marked]="markedElement"></article-changed-marker>
  </div>
</div>
