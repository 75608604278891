import {NgModule} from '@angular/core';
import {SharedModule} from '../../../core/shared.module';
import {ArticleDuplicateComponent} from './duplicate/article-duplicate';
import {ArticleDuplicateModalComponent} from './duplicate/article-duplicate-modal';
import {UIRouterModule} from '@uirouter/angular';
import {ArticleNavigationModule} from '../navigiation/article-navigation.module';
import {ArticleLockModule} from './lock/article-lock.module';
import {ArticleHistoryComponent} from './history/article-history.component';
import {ArticlePreviewModalComponent} from './history/article-preview.modal.component';
import {ArticleSisterModalComponent} from './sister-modal/article-sister-modal.component';
import {ArticleSdsModalComponent} from './sds-modal/article-sds-modal.component';
import {ArticleCopyModalComponent} from './copy-modal/article-copy-modal.component';
import {ArticleCreateComponent} from './create/article-create.component';
import {ArticleXyInputComponent} from './form/article-xy-input.component';
import {ArticleInputComponent} from './form/article-input.component';
import {ArticleSelectComponent} from './form/article-select.component';
import {ArticleLabelComponent} from './form/article-label.component';
import {CustomerBoxModule} from '../../customer/box/customer-box.module';
import {ArticleMultiComponent} from './form/article-multi.component';
import {ArticleCheckboxComponent} from './form/article-checkbox.component';
import {ArticleAbCheckboxComponent} from './form/article-ab-checkbox.component';
import {ArticleOverviewTemplateComponent} from './helper/article-overview-template.component';
import {ArticleDetailComponent} from './detail/article-detail.component';
import {ArticleMatrixComponent} from './form/article-matrix.component';
import {ArticleDetailMatrixComponent} from './detail/article-detail-matrix.component';
import {ArticleInputCalcComponent} from './form/article-input-calc.component';
import {ArticleOverviewComponent} from './detail/article-overview.component';
import {ArticleLockedInfoComponent} from './detail/article-locked-info.component';
import {ArticleMainSaveModalComponent} from './save-modal/article-main-save-modal.component';
import {ArticleHistoryDetailComponent} from './history/article-history-detail.component';
import {ArticleHistorySimpleComponent} from './history/article-history-simple.component';
import {CommentModule} from '../../comment/comment.module';
import {ArticleXyInputTitleComponent} from './form/article-xy-input-title.component';
import {ArticleHdiFormComponent} from './helper/article-hdi-form.component';
import {ArticleInputOptionalComponent} from './form/article-input-optional.component';
import {ArticleElectroplatingFormComponent} from './helper/electroplating/article-electroplating-form.component';
import {ArticleOptionalGroupComponent} from './form/article-optional-group.component';
import {ArticleMultilayerPlanCreateComponent} from './multilayer-plan/article-multilayer-plan-create.component';
import {ArticleMultilayerPlanUpdateComponent} from './multilayer-plan/article-multilayer-plan-update.component';
import {ArticleMultilayerPlanHolderComponent} from './multilayer-plan/article-multilayer-plan-holder.component';
import {MaterialManagementModule} from '../../material/material-management.module';
import {ArticleMultilayerPlanHistoryComponent} from './history/article-multilayer-plan-history.component';
import {
  ArticleMultilayerPlanCopySelectModalComponent
} from './multilayer-plan/copy-select/article-multilayer-plan-copy-select-modal.component';
import {LoadingOrErrorModule} from '../../../common/loading/loading-or-error.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ArticleSelectTitleComponent} from './form/article-select-title.component';
import {ArticleCheckboxWithSelectComponent} from './form/article-checkbox-with-select.component';
import {ArticleChangedMarkerComponent} from './form/article-changed-marker.component';
import { ArticleSurfaceTypeFormComponent } from './helper/surface-type/article-surface-type-form.component';
import { ArticleViaHandlingFormComponent } from './helper/via-handling/article-via-handling-form.component';
import {ArticleInputStringComponent} from './form/article-input-string.component';

@NgModule({
    imports: [
        UIRouterModule.forChild(),
        SharedModule,
        ArticleNavigationModule,
        ArticleLockModule,
        CustomerBoxModule,
        CommentModule,
        MaterialManagementModule,
        LoadingOrErrorModule,
        NgbModule,
    ],
    declarations: [
        ArticleDetailComponent,
        ArticleDetailMatrixComponent,
        ArticleInputCalcComponent,
        ArticleOverviewComponent,
        ArticleLockedInfoComponent,
        ArticleCreateComponent,
        ArticleDuplicateComponent,
        ArticleHistoryComponent,
        ArticleHistorySimpleComponent,
        ArticleHistoryDetailComponent,
        // modal
        ArticleMainSaveModalComponent,
        ArticleDuplicateModalComponent,
        ArticlePreviewModalComponent,
        ArticleSisterModalComponent,
        ArticleSdsModalComponent,
        ArticleCopyModalComponent,
        // shareable
        ArticleOverviewTemplateComponent,
        // form
        ArticleMatrixComponent,
        ArticleMultiComponent,
        ArticleCheckboxComponent,
        ArticleXyInputComponent,
        ArticleXyInputTitleComponent,
        ArticleInputComponent,
        ArticleSelectComponent,
        ArticleLabelComponent,
        ArticleAbCheckboxComponent,
        ArticleHdiFormComponent,
        ArticleInputOptionalComponent,
        ArticleElectroplatingFormComponent,
        ArticleOptionalGroupComponent,
        ArticleMultilayerPlanHolderComponent,
        ArticleMultilayerPlanCreateComponent,
        ArticleMultilayerPlanUpdateComponent,
        ArticleMultilayerPlanHistoryComponent,
        ArticleMultilayerPlanCopySelectModalComponent,
        ArticleSelectTitleComponent,
        ArticleCheckboxWithSelectComponent,
        ArticleChangedMarkerComponent,
        ArticleSurfaceTypeFormComponent,
        ArticleViaHandlingFormComponent,
        ArticleInputStringComponent,
    ],
    exports: [
        ArticleInputComponent,
        ArticleLockedInfoComponent,
    ]
})
export class ArticleMainModule {
}
