<td>{{materialChoice.inventory.supplier_name}}</td>
<td>
  <div [ngbTooltip]="materialChoice.inventory.variation_id">
    {{materialChoice.inventory.article_name}}&nbsp;
    <i class="fa fa-star star-yellow"
       ngbTooltip="bevorzugtes Material"
       *ngIf="materialChoice.inventory.variation.favorite"></i>
  </div>
</td>
<td class="align-right">{{materialChoice.inventory.variation?.vendor_price|money:2}}</td>

<td [ngClass]="{'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
  <material-field-value [field]="field" [fields]="materialChoice.inventory.fields">
  </material-field-value>
</td>

<td class="align-right">
  <a ngbTooltip="Bestand im Materiallager"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: materialChoice.inventory.id}">
    {{materialChoice.inventory.amount|money}}
  </a>
</td>

<td class="align-right" *ngIf="materialChoice.has_selected_date">
  <a ngbTooltip="Unbestätigte Bestellungen aller BAs im ausgewählten Zeitraum"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: materialChoice.inventory.id}">
    <span class="label label-default">{{materialChoice.total_ordered_to_selected_date|money}}</span>
  </a>
</td>
<td class="align-right">
  <a ngbTooltip="Unbestätigte Bestellungen Gesamt"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: materialChoice.inventory.id}">
    {{materialChoice.inventory.amount_ordered|money}}
  </a>
</td>

<td class="align-right" *ngIf="materialChoice.has_selected_date">
  <a ngbTooltip="Bestätigte Bestellungen aller BAs im ausgewählten Zeitraum"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: materialChoice.inventory.id}">
    <span class="label label-default">{{materialChoice.total_confirmed_to_selected_date|money}}</span>
  </a>
</td>
<td class="align-right">
  <a ngbTooltip="Bestätigte Bestellungen Gesamt"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: materialChoice.inventory.id}">
    {{materialChoice.inventory.amount_confirmed|money}}
  </a>
</td>
<td class="align-right">
  <a ngbTooltip="Bestätigte überfällige Bestellungen Gesamt"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: materialChoice.inventory.id}">
    <div [class]="{'red-font': materialChoice.inventory.amount_confirmed_open > 0}">
      {{materialChoice.inventory.amount_confirmed_open|money}}
    </div>
  </a>
</td>

<td class="align-right" *ngIf="materialChoice.has_selected_customer">
  <a ngbTooltip="Planung für Kunde"
     uiSref="a.business_order.list"
     target="_blank"
     [uiParams]="linkParams">
    <span class="label" [ngClass]="{ 'label-info': !markRedCustomer, 'label-warning': markRedCustomer }">
      {{materialChoice.total_required_customer|money}}
    </span>
  </a>
</td>
<td class="align-right" *ngIf="materialChoice.has_selected_date">
  <a ngbTooltip="Planung aller BAs im ausgewählten Zeitraum"
     uiSref="a.business_order.list"
     target="_blank"
     [uiParams]="linkParams">
    <span class="label" [ngClass]="{ 'label-default': !markOrangeToSelectedDate, 'label-warning': markOrangeToSelectedDate }">
      {{materialChoice.total_required_to_selected_date|money}}
    </span>
  </a>
</td>
<td class="align-right">
  <a ngbTooltip="Planung Gesamt"
     uiSref="a.business_order.list"
     target="_blank"
     [uiParams]="{variation_id: materialChoice.inventory.id, status: 0}">
    <span [ngClass]="{'orange-cfa': markOrange && !markRed, 'label': markRed, 'label-danger': markRed}">
      {{materialChoice.inventory.amount_planned|money}}
    </span>
  </a>
</td>

<ng-container *ngFor="let week of weeksWithColors">
  <td class="align-right click vertical-black-border-left" [ngClass]="week.color1" (click)="setSelectedWeek(week)">
    <div class="inline"
         [ngbTooltip]="'Bestätigt ' + materialChoice.inventory.variation.variation_id + ' ' + materialChoice.inventory.variation.article.name + ' in KW ' + week.week">
      {{ week.confirmed|money }}
    </div>
  </td>
  <td class="align-right click" [ngClass]="week.color2" (click)="setSelectedWeek(week)">
    <div class="inline"
         [ngbTooltip]="'Geplant ' + materialChoice.inventory.variation.variation_id + ' ' + materialChoice.inventory.variation.article.name + ' in KW ' + week.week">
      {{ week.required|money }}
    </div>
  </td>
</ng-container>

<td>
  <a uiSref="a.material.order.create"
     target="_blank"
     [ngbTooltip]="'Material ' + materialChoice.inventory.variation.variation_id + ' ' + materialChoice.inventory.variation.article.name + ' Bestellen'"
     [uiParams]="{supplier_id: materialChoice.inventory.supplier_id, marked_variation_id: materialChoice.inventory.id}">
    <i class="fa fa-truck"></i>
  </a>
</td>
