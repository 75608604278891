<td [ngClass]="this.rowClass">
  {{material.inventory.supplier_name}}
</td>
<td [ngClass]="this.rowClass">
  <a uiSref="a.material.inventory.update"
     target="_blank"
     [ngbTooltip]="material.inventory.variation_id"
     [uiParams]="{variation: material.inventory.id}">
    {{material.inventory.article_name}}
  </a>
  <i class="fa fa-star star-yellow"
     ngbTooltip="bevorzugtes Material"
     *ngIf="material.inventory.variation.favorite">
  </i>
</td>

<td [ngClass]="this.rowClass || {'align-right': field.isValueType('Number')}" *ngFor="let field of fields">
  <material-field-value [field]="field" [fields]="material.inventory.fields">
  </material-field-value>
</td>

<td class="align-right" [ngClass]="this.rowClass">
  <a ngbTooltip="Bestand im Materiallager"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: material.inventory.id}">
    {{material.inventory.amount|money}}
  </a>
</td>
<td class="align-right" [ngClass]="this.rowClass">
  <a ngbTooltip="Unbestätigte Bestellungen Gesamt"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: material.inventory.id}">
    {{material.inventory.amount_ordered|money}}
  </a>
</td>
<td class="align-right" [ngClass]="this.rowClass">
  <a ngbTooltip="Bestätigte Bestellungen Gesamt"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: material.inventory.id}">
    {{material.inventory.amount_confirmed|money}}
  </a>
</td>
<td class="align-right" [ngClass]="this.rowClass">
  <a ngbTooltip="Bestätigte überfällige Bestellungen Gesamt"
     uiSref="a.material.inventory.update"
     target="_blank"
     [uiParams]="{variation: material.inventory.id}">
    <div [class]="{'red-font': material.inventory.amount_confirmed_open > 0}">
      {{material.inventory.amount_confirmed_open|money}}
    </div>
  </a>
</td>
<td class="align-right" [ngClass]="this.rowClass">
  <a ngbTooltip="Planung Gesamt"
     uiSref="a.business_order.list" target="_blank" [uiParams]="{variation_id: material.inventory.id}">
    {{material.inventory.amount_planned|money}}
  </a>
</td>

<ng-container *ngFor="let week of weeksWithColors">
  <td class="align-right click vertical-black-border-left" [ngClass]="week.class_left" (click)="setMarkedWeek(week)">
    <div class="inline h-full w-full"
         [ngbTooltip]="'Bestätigt ' + material.inventory.variation.variation_id + ' ' + material.inventory.variation.article.name + ' in KW ' + week.week">
      {{ week.confirmed|money }}
    </div>
  </td>
  <td class="align-right click" [ngClass]="week.class_right" (click)="setMarkedWeek(week)">
    <div class="inline h-full w-full"
         [ngbTooltip]="'Geplant ' + material.inventory.variation.variation_id + ' ' + material.inventory.variation.article.name + ' in KW ' + week.week">
      {{ week.required|money }}
    </div>
  </td>

</ng-container>

<td>
  <ng-container *ngIf="!lockSelection">
    <a class="click" (click)="selectMaterial()">
      {{ this.conflictVariation ? 'Material trotzdem Auswählen' : 'Auswählen' }}
    </a>
  </ng-container>
  <ng-container *ngIf="lockSelection">
    {{ this.conflictVariation ? 'Material trotzdem Auswählen' : 'Auswählen' }}
  </ng-container>
</td>
