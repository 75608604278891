<article-top-navigation [article]="article"
                        [workflowId]="workflowId"
                        [multilayerPlan]="multilayerPlan"
                        *ngIf="article">
</article-top-navigation>

<article-top [edit]="true" [create]="!article" [article]="article" [topForm]="form" (updateState)="updateState($event)">
</article-top>

<article-locked-info [article]="article"></article-locked-info>

<div class="row top-buffer">
  <form class="form-horizontal small-input input-align-right small-form" [formGroup]="form">

    <!-- Article Stuff-->
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <!-- Box 1 -->
        <article-xy-input label="LP-Größe" unit="mm" [form]="form"
                          model1="board_size1" model2="board_size2"
                          (changeEmitter)="calculated($event)" [specification]="specification"
                          [error1]="errors['board_size1']" [error2]="errors['board_size2']">
        </article-xy-input>

        <article-xy-input label="Liefernutzen-Gr." model1="utilize_size1" model2="utilize_size2" unit="mm"
                          [form]="form" (changeEmitter)="calculated($event)"
                          [error1]="errors['utilize_size1']" [error2]="errors['utilize_size2']"
                          [specification]="specification"></article-xy-input>

        <article-input label="LP/Liefernu." model="utilize1" [form]="form"
                       (changeEmitter)="calculated($event)" [error]="errors['utilize1']"></article-input>

        <article-input label="Liefernu./Fe.Nu." model="utilize2" [form]="form"
                       (changeEmitter)="calculated($event)" [error]="errors['utilize2']"></article-input>

        <article-input label="LP/Fe.Nu." model="utilize_sum" [form]="form" [isDisabled]="true"
                       (changeEmitter)="calculated($event)" [error]="errors['utilize_sum']"></article-input>

        <article-input label="LP-Fläche" model="surface" [form]="form" unit="dm²" [error]="errors['surface']" [isDisabled]="true">
        </article-input>

        <hr>

        <article-checkbox label="Spezial Artikel" model="special_production" [form]="form"
                          [error]="errors['special_production']"></article-checkbox>

        <article-select label="LP Typ" model="execution" [form]="form" [specification]="specification"
                        [error]="errors['execution']"></article-select>

        <article-surface-type-form [form]="form" [specification]="specification" [errors]="errors">
        </article-surface-type-form>

        <article-select label="Basismaterial" model="manufacturer" [form]="form" [specification]="specification"
                        [refCount]="refCount" [error]="errors['manufacturer']"></article-select>

        <article-label label="Material Kundenvorgabe"
                       model="customer_specification_material_type"
                       [form]="form"
                       [specification]="specification"
                       [nullable]="true"
                       [plain]="true"
                       *ngIf="form.value.customer_specification_material"></article-label>

        <article-label label="TG" model="tg" unit="C°" [form]="form" [specification]="specification"
                       [refCount]="refCount"></article-label>
        <article-label label="Halogenfrei" model="halogen_free" [bool]="true" [form]="form"
                       [specification]="specification" [refCount]="refCount"></article-label>
        <article-label label="DE konform" model="de_compliant" [bool]="true" [form]="form"
                       [specification]="specification" [refCount]="refCount" [nullable]="true">
        </article-label>
        <article-label label="China konform" model="china_compliant" [bool]="true" [form]="form"
                       [specification]="specification" [refCount]="refCount"></article-label>

        <article-select label="Lötstopplack A" model="solder_resist_a" [form]="form" [specification]="specification"
                        [error]="errors['solder_resist_a']"></article-select>

        <article-select label="Lötstopplack B" model="solder_resist_b" [form]="form" [specification]="specification"
                        [error]="errors['solder_resist_b']"></article-select>

        <article-label label="Lack Kundenvorgabe"
                       model="customer_specification_solder_resist_type"
                       [form]="form"
                       [specification]="specification"
                       [nullable]="true"
                       [plain]="true"
                       *ngIf="form.value.customer_specification_solder_resist"></article-label>

        <hr>
        <article-select label="Cu Endstärke" model="cu_thickness" unit="µm" [form]="form"
                        [specification]="specification" [error]="errors['cu_thickness']"></article-select>

        <article-select label="Basiskupfer" model="cu_base" unit="µm" [form]="form"
          [specification]="specification" [error]="errors['cu_base']"></article-select>

        <article-multi [form]="form" [specification]="specification" [refCount]="refCount" [errors]="errors">
          <ng-template let-item="item">
            <article-select [label]="'Cu Innen ' + item.index"
                            [model]="'cu_inside_' + item.index"
                            [error]="item.errors['cu_inside_' + item.index]"
                            [form]="form"
                            [specification]="item.specification"
                            unit="µm"
                            [zero]="true">
            </article-select>
          </ng-template>
        </article-multi>

        <article-input label="LP Endstärke" model="endintensity" unit="mm" [form]="form"
                       [error]="errors['endintensity']"></article-input>

        <article-input label="Min. Bohr." model="drilling" [form]="form" unit="mm" (changeEmitter)="aspectCalculated()"
                       [error]="errors['drilling']"></article-input>

        <article-input label="Bohrenddurchm." model="drilling_diameter" [form]="form" unit="mm"
                       [error]="errors['drilling_diameter']"></article-input>

        <article-input label="Min. Fräs." model="min_milling_diameter" [form]="form" unit="mm"
                       [error]="errors['min_milling_diameter']"></article-input>
      </div>


      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <!-- Box 2 -->
        <article-input label="Senkungen" model="lowerings" [form]="form" unit="Stk/LP"
                       [error]="errors['lowerings']"></article-input>
        <article-input label="Captonband" model="capton_band" [form]="form" unit="Stk/LP"
                       [error]="errors['capton_band']"></article-input>
        <article-input label="Bohrungen / LP" model="drilldensity" [form]="form"
                       [error]="errors['drilldensity']"></article-input>
        <article-input label="Präzisionsbohrungen" model="fix_drill" [form]="form" unit="Stk/LP"
                       [error]="errors['fix_drill']"></article-input>

        <article-input label="Nutzen Auslastung" model="utilize_utilization" [form]="form" unit="%"
                       [error]="errors['utilize_utilization']"></article-input>
        <hr>

        <!-- microvias group -->
        <article-hdi-form [form]="form" [errors]="errors" [refCount]="refCount" [specification]="specification">
        </article-hdi-form>

        <hr />

        <article-via-handling-form [form]="form"
                                   [errors]="errors"
                                   [specification]="specification"
                                   [refCount]="refCount"
                                   [stacked]="true"
                                   (setAutomatedChange)="setAutomatedChangeEmitter.emit($event)">
        </article-via-handling-form>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <!-- Box 3 -->
        <article-ab-checkbox label="Lötabdecklack" [form]="form"
                             model1="protective_lacquer_ls" [error1]="errors['protective_lacquer_ls']"
                             model2="protective_lacquer_ds" [error2]="errors['protective_lacquer_ds']">
        </article-ab-checkbox>

        <article-ab-checkbox label="Sacklochtech" [form]="form"
                             model1="blind_hole_tech_ls" [error1]="errors['blind_hole_tech_ls']"
                             model2="blind_hole_tech_ds" [error2]="errors['blind_hole_tech_ds']">
        </article-ab-checkbox>

        <article-select label="Best-Druck A" model="best_pressure_a" [form]="form" [specification]="specification"
                        [error]="errors['best_pressure_a']"></article-select>
        <article-select label="Best-Druck B" model="best_pressure_b" [form]="form" [specification]="specification"
                        [error]="errors['best_pressure_b']"></article-select>

        <hr>

        <article-checkbox label="Goldstecker" model="gold_plug" [form]="form"
                          [error]="errors['gold_plug']"></article-checkbox>
        <article-checkbox label="Konturfräsen" model="shape_mill" [form]="form"
                          [error]="errors['shape_mill']"></article-checkbox>
        <article-checkbox label="Fräsen" model="mill" [form]="form"
                          [error]="errors['mill']"></article-checkbox>
        <article-checkbox label="Ritzen" model="scratch" [form]="form"
                          [error]="errors['scratch']"></article-checkbox>

        <hr>

        <article-checkbox label="2x Galvanisiert" model="galvanized_two" [form]="form"
                          [error]="errors['galvanized_two']"></article-checkbox>
        <article-checkbox label="Kontaktkamm" model="contact_fanning_strip" [form]="form"
                          [error]="errors['contact_fanning_strip']"></article-checkbox>
        <article-checkbox label="Buried Vias" model="buried_vias" [form]="form"
                          [error]="errors['buried_vias']"></article-checkbox>
        <article-checkbox label="Keine fehl. LPs" model="no_lp_missed" [form]="form"
                          [error]="errors['no_lp_missed']"></article-checkbox>
        <article-checkbox label="Schlechtnutzen" model="bad_utilize" [form]="form"
                          [error]="errors['bad_utilize']"></article-checkbox>

        <hr>

        <article-checkbox label="E-Test einseitig" model="testing_1smd" [form]="form"
                          [error]="errors['testing_1smd']"></article-checkbox>
        <article-checkbox label="E-Test zweiseitig" model="testing_2smd" [form]="form"
                          [error]="errors['testing_2smd']"></article-checkbox>
        <article-checkbox label="Impedanzkalkulation" model="impedance_calculation" [form]="form"
                          [error]="errors['impedance_calculation']"></article-checkbox>
        <article-checkbox label="Impedanzprüfung" model="impedance_inspection" [form]="form"
                          [error]="errors['impedance_inspection']"></article-checkbox>

        <!-- End Box 3 -->
      </div>
      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
        <h5>Schlechte %</h5>
        <article-input label="Schlechte DE" model="schlechte_de" [form]="form" unit="%"
                       [error]="errors['schlechte_de']"></article-input>
        <article-input label="Schlechte CN" model="schlechte_cn" [form]="form" unit="%"
                       [error]="errors['schlechte_cn']"></article-input>

        <hr>
        <h5>Zuschläge €</h5>
        <article-input label="Zuschlag DE" model="surcharge_de" [form]="form" unit="€"
                       [error]="errors['surcharge_de']"></article-input>

        <hr>
        <div class="article-customer">
          <customer-box [customer]="articleCustomer"
                        [ngClass]="{red_alert: errors['obj.customer']}"
                        (updateEmitter)="customerChange($event)"
                        [clear]="false">
          </customer-box>
        </div>
      </div>
    </div>

  </form>
</div>
