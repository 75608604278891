<div class="buttonbar-view">
  <a uiSref="a.material.inventory.create" title="Artikel Einpflegen" class="btn btn-default">
    <span class="fa fa-plus"></span>
  </a>
  <material-planning-force-check-button (reloaded)="search()"
                                        *ngIf="('material.planning.manual.start'|HasPerm)">
  </material-planning-force-check-button>
</div>

<form [formGroup]="form">
  <div class="row" [formGroup]="form">
    <div [ngClass]="{'col-lg-12': !selectedWeek, 'col-lg-10': !!selectedWeek}">
      <h3>Material Planung</h3>
      <div class="filter-row">
        <div class="filter-left">
          <material-product-groups-tab-list [productGroups]="productGroups.objects"
                                            [groups]="groups.objects"
                                            [activeGroupIds]="[currentGroupId]"
                                            [singleMode]="true"
                                            (setGroupId)="selectGroup($event)">
          </material-product-groups-tab-list>
        </div>

        <div class="filter-right">
          <button class="btn btn-sm btn-warning" type="button" (click)="toggleOn('cfa', true)">
            <i class="fa" [ngClass]="{'fa-toggle-on': form.value.cfa, 'fa-toggle-off': !form.value.cfa}">
            </i>
            &nbsp;Zu wenig Material
          </button>

          <button class="btn btn-sm btn-danger" type="button" (click)="toggleOn('cfa_by_date', true)">
            <i class="fa" [ngClass]="{'fa-toggle-on': form.value.cfa_by_date, 'fa-toggle-off': !form.value.cfa_by_date}">
            </i>
            &nbsp;Termin nicht möglich
          </button>
        </div>
      </div>

      <table class="table whitetable" *ngIf="fields.data$|async as fieldList; else loadingOrError.template">
        <thead>
        <tr>
          <th [colSpan]="4 + fieldList.length + numInventoryCols * 2 + numPlanningCols" rowspan="3">
            <div class="row">
              <div class="col-sm-4"></div>
              <div class="col-sm-8">
                <span [ngClass]="{label: hasSelectedDate, 'label-success': hasSelectedDate}">
                  Bestellungen und BAs berücksichtigen:
                </span>
                <button type="button"
                        class="btn btn-default btn-sm"
                        (click)="unsetDateLimit()"
                        *ngIf="hasSelectedDate">
                  <i class="fa fa-trash-o"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <customer-box buttonLabel="Kunde auswählen"
                              [customer]="customer"
                              [disabledHeader]="true"
                              (updateEmitter)="selectCustomer($event)">
                </customer-box>
              </div>
              <div class="col-sm-1">
                <label for="date-limit-from" class="control-label">Von:</label>
              </div>
              <div class="col-sm-3">
                <ev-datepicker id="date-limit-from"
                               formControlName="date_limit_from"
                               class="form-control input-sm"
                               [allDates]="true"
                               [future]="true"
                               [ngClass]="{red_alert: !!filterFormErrors.date_limit_from}">
                </ev-datepicker>
              </div>
              <div class="col-sm-1">
                <label for="date-limit-to" class="control-label">Bis:</label>
              </div>
              <div class="col-sm-3">
                <ev-datepicker id="date-limit-to"
                               formControlName="date_limit_to"
                               class="form-control input-sm"
                               [allDates]="true"
                               [future]="true"
                               [ngClass]="{red_alert: !!filterFormErrors.date_limit_to}">
                </ev-datepicker>
              </div>
            </div>
          </th>
          <th class="align-right" rowspan="3">
            <i class="fa fa-arrow-circle-left fa-2x ev-blue-color"
               ngbTooltip="Woche früher"
               (click)="weekScrollPage(-1)">
            </i>
          </th>
          <th colspan="2" *ngFor="let index of weekPaginator.visibleWeekIndices"><strong>KW</strong></th>
          <th rowspan="3">
            <i class="fa fa-arrow-circle-right fa-2x ev-blue-color"
               ngbTooltip="Woche später"
               (click)="weekScrollPage(1)">
            </i>
          </th>
        </tr>
        <tr>
          <th colspan="2" *ngFor="let index of weekPaginator.visibleWeekIndices">
            <strong [ngbTooltip]="'Ab ' + (list.weeks[index].first_day_of_week|date:'dd.MM.yyyy')">
              {{list.weeks[index].week}}/{{list.weeks[index].first_day_of_week|date:'yy'}}
            </strong>
          </th>
        </tr>
        <tr>
          <th colspan="2"
              style="font-size: 10px;"
              *ngFor="let index of weekPaginator.visibleWeekIndices">
            {{list.weeks[index].first_day_of_week|date:'dd.MM.yyyy'}}
          </th>
        </tr>
        <tr>
          <th>
            <a class="click" (click)="sort('supplier')">
              Lieferant
            </a>
          </th>
          <th>
            <a class="click" (click)="sort('article_name')">
              Bezeichnung
            </a>
          </th>
          <th class="align-right">
            <a class="click" (click)="sort('vendor_price')">
              Preis
            </a>
          </th>
          <th *ngFor="let field of fieldList">
            <a class="click" (click)="sort(field.html_name)">
              {{field.name}}
            </a>
          </th>
          <th class="align-right">Lagerbestand</th>
          <th [ngClass]="{'align-right': numInventoryCols < 2}" [colSpan]="numInventoryCols">Bestellt</th>
          <th [ngClass]="{'align-right': numInventoryCols < 2}" [colSpan]="numInventoryCols">Bestätigt</th>
          <th class="align-right"><span ngbTooltip="Überfällig">Überf.</span></th>
          <th [ngClass]="{'align-right': numPlanningCols < 2}" [colSpan]="numPlanningCols">Planung</th>
          <ng-container *ngFor="let index of weekPaginator.visibleWeekIndices">
            <th class="align-right">
              <i class="fa fa-truck"
                 [ngbTooltip]="'bestätigte Lieferungen in KW ' + list.weeks[index].week">
              </i>
            </th>
            <th class="align-right">
              <i class="fa fa-handshake-o"
                 [ngbTooltip]="'geplante Materialnutzung in KW ' + list.weeks[index].week">
              </i>
            </th>
          </ng-container>
          <th></th>
        </tr>
        <tr class="filterrow">
          <th>
            <select class="form-control input-sm"
                    name="supplier_id"
                    formControlName="supplier_id"
                    [ngClass]="{ 'red_alert': !!filterFormErrors.supplier_id }">
              <option [ngValue]="null">-</option>
              <option [ngValue]="supplier.id" *ngFor="let supplier of suppliers">
                {{supplier.name}}
              </option>
            </select>
          </th>

          <th>
            <ng-container *ngIf="articleNames.data$ | async as articles; else loadingOrError.template">
              <select class="form-control input-sm"
                      name="article_name"
                      formControlName="article_name"
                      [ngClass]="{ 'red_alert': !!filterFormErrors.article_name }">
                <option [ngValue]="null">-</option>
                <option [ngValue]="article" *ngFor="let article of articles">{{article}}</option>
              </select>
            </ng-container>
            <loading-or-error #loadingOrError
                              [loadingWrapper]="articleNames"
                              [errorMessage]="'Konnte Artikelnamen nicht laden'">
            </loading-or-error>
          </th>
          <th></th>
          <th *ngFor="let field of fieldList">
            <ng-container [ngSwitch]="field.data_type">
              <ng-container *ngSwitchCase="'Auswahl'">
                <select class="form-control input-sm"
                        [name]="field.html_name"
                        [ngClass]="{ 'red_alert': !!filterFormErrors[field.html_name] }"
                        [formControlName]="field.html_name">
                  <option [ngValue]="null" selected>-</option>
                  <option [ngValue]="choice" *ngFor="let choice of field.choices">{{choice}}</option>
                </select>
              </ng-container>
              <ng-container [ngSwitch]="this.form[field.html_name]" *ngSwitchCase="'Option'">
                <i class="fa fa-check click" (click)="triStateChange(field)" *ngSwitchCase="true"></i>
                <i class="fa fa-check click" (click)="triStateChange(field)" *ngSwitchCase="'true'"></i>
                <i class="fa fa-times click" (click)="triStateChange(field)" *ngSwitchCase="false"></i>
                <i class="fa fa-times click" (click)="triStateChange(field)" *ngSwitchCase="'false'"></i>
                <i class="fa fa-check click gray_font" (click)="triStateChange(field)" *ngSwitchDefault></i>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input class="form-control input-sm"
                       [name]="field.html_name"
                       [ngClass]="{ 'red_alert': !!filterFormErrors[field.html_name] }"
                       [formControlName]="field.html_name">
              </ng-container>
            </ng-container>
          </th>

          <th [colSpan]="3 + numInventoryCols * 2 + numPlanningCols + weekPaginator.visibleWeekIndices.length * 2"></th>
        </tr>
        </thead>

        <tbody>
        <tr [material-planning-row]="material"
            [material-planning-row-week-pages]="weekPaginator.visibleWeekIndices"
            [material-planning-row-selected-week]="selectedWeek"
            [material-planning-row-selected-variation-id]="selectedVariationId"
            [material-planning-row-start-date]="startDate"
            [material-planning-row-customer]="customer"
            [material-planning-row-fields]="fieldList"
            (material-planning-row-select-week)="setSelectedWeek($event, material.inventory.id)"
            *ngFor="let material of list.objects">
        </tr>
        </tbody>
      </table>
      <div *ngIf="list && list.count === 0">
        Keine Material Artikel mit diesen Kriterien Gefunden
      </div>
      <ev-navigation [page]="list.page" [pages]="list.pages" [query]="form.value" (goto)="goto($event)"></ev-navigation>

      <loading-or-error #loadingOrError
                        [loadingWrapper]="fields"
                        [errorMessage]="'Konnte Material Felder für Gruppe nicht laden'"
                        [spinnerSize]="2"
                        spinnerName="circle-o-notch">
      </loading-or-error>
    </div>
    <div class="col-lg-2" *ngIf="selectedWeek && selectedVariationId">
      <material-planning-sidebar [inventory]="selectedVariation"
                                 [week]="selectedWeek"
                                 (closeEvent)="setSelectedWeek(null, null)">
      </material-planning-sidebar>
    </div>
  </div>
</form>
